import React, { useEffect } from "react";
// translation
import { useTranslation } from "react-i18next";
// router
import { useHistory } from "react-router-dom";
// antd
import { Spin, message } from "antd";
// redux
import { useSelector, useDispatch } from "react-redux";
import {
  createOrder,
  sendSingleBoardingDealToHubspot,
  setErrors,
} from "../../../../store/slices/orders";
import { fetchUsers } from "../../../../store/slices/user";
// components
import { CreateOrderForm } from "../../../../components";
// helpers
import { setCreateOrderData } from "../../../../store/slices/orders";

import "./styles.less";

const productType = {
  OPEN_POLICY: "cargo",
  SINGLE_BOARDING: "cargo",
  SURETY: "surety",
  LIFE: "life",
};

const CreateOrder = ({ setShowModal }) => {
  const {
    orders: { errors, isLoading },
    user: { userList },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translation } = useTranslation();

  const getUsers = () => {
    const users = [...userList.filter((item) => item.salesProfile)];
    return users.sort((a, b) => a.name.localeCompare(b.name));
  };

  const handleSubmit = (values) => {
    if (!productType[values.type]) {
      return message.error(translation("order.createOrder.typeErrorMessage"));
    }
    dispatch(createOrder(values, productType[values.type])).then((response) => {
      const { code } = response.data;
      if (values.type === "SINGLE_BOARDING" && code) {
        dispatch(sendSingleBoardingDealToHubspot(code, values.email)).then(
          () => {
            history.push(`/crm/orders/${code}`);
            setShowModal(false);
          }
        );
      }
      history.push(`/crm/orders/${code}`);
      setShowModal(false);
      dispatch(setErrors([]));
      dispatch(setCreateOrderData({}));
    });
  };

  useEffect(() => {
    dispatch(fetchUsers());
    return () => {
      dispatch(setErrors([]));
    };
  }, []);

  return (
    <Spin spinning={isLoading}>
      <CreateOrderForm
        users={getUsers()}
        errors={errors}
        onSubmit={handleSubmit}
        setErrors={setErrors}
      />
    </Spin>
  );
};

export default CreateOrder;
