import React from "react";
import { formatDateTime } from "../../../../helpers";
//Translation
import { useTranslation } from "react-i18next";
//Components
import { Row, Col, Tooltip, Icon } from "antd";
import { Redirect, FormattedValue } from "components";
//Configs
import OrderConfig from "../../Config";
// config
import config from "config";
//Styles
import "./Styles.less";
//Constants
const { typeEntriesColors, orderTypeTranslation, bondTypes } = OrderConfig;
const typesAvailable = ["GENERAL"];

const CardItem = ({ label, value, documentNumber, phoneNumber, tip = "" }) => {
  const verifyDocumentType = (docNumber) => {
    if (docNumber && docNumber.length < 14) {
      return "INDIVIDUAL";
    } else {
      return "COMPANY";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        marginBottom: "8px",
      }}
    >
      <p style={{ padding: 0, margin: 0 }}>
        {label}

        <Tooltip title={tip} placement="right">
          <Icon type="question-circle" className="OrderHeader-icon" />
        </Tooltip>
      </p>
      <p style={{ padding: 0, margin: 0, color: "#BFBFBF" }}>
        {value && value}
        {phoneNumber && (
          <FormattedValue
            mask="_"
            displayType="text"
            value={phoneNumber}
            format={"contactPhone"}
          />
        )}
        {documentNumber && (
          <FormattedValue
            mask="_"
            displayType="text"
            value={documentNumber}
            format={verifyDocumentType(documentNumber)}
          />
        )}
      </p>
    </div>
  );
};

const OrderItem = ({ order }) => {
  const { t: translation } = useTranslation();
  const orderTypeColor = typeEntriesColors[order.type];
  const {
    id,
    companyName,
    documentNumber,
    contactName,
    email,
    phoneNumber,
    createdAt,
    updatedAt,
    type,
    bondType,
    code,
  } = order;

  function getOrderType() {
    if (typesAvailable.includes(type) && bondType) {
      const types = bondTypes[type];
      if (types.hasOwnProperty(bondType)) {
        return types[bondType];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const formPathByProduct = {
    CARGO_OPEN_POLICY: "seguro-de-carga/multiplo",
    CARGO_SINGLE_BOARDING: "seguro-de-carga/unico",
    SURETY: "seguro-garantia",
    LIFE: "seguro-de-vida",
  };

  const formUrl = code
    ? `${config.formsUrl}/${formPathByProduct[type]}/dados-iniciais/${code}`
    : "";

  return (
    <div className="OrderItem">
      <Row type="flex" justify="space-between" align="middle">
        <div
          className="OrderItem-tag"
          style={{ backgroundColor: `${orderTypeColor}`, borderRadius: "8px" }}
        >
          <p style={{ textTransform: "uppercase" }}>
            {type && orderTypeTranslation[type]}
          </p>
          <a href={formUrl} target="_blank" style={{ color: "white" }}>
            {code}
          </a>
        </div>
      </Row>

      <Redirect
        to={{ pathname: `orders/${id}`, order: `${companyName}` }}
        key={id}
      >
        <div className="gx-module-list-item OrderItem-content gx-px-0">
          <div className="gx-module-list-content">
            <Row type="flex">
              <Col xs={24} lg={12}>
                {companyName && (
                  <CardItem
                    label={translation("orderDetails.companyNameLabel")}
                    value={companyName}
                    tip={translation("orderDetails.companyName.tip")}
                  />
                )}
                {companyName && (
                  <CardItem
                    label={translation("orderDetails.contactNameLabel")}
                    value={contactName}
                    tip={translation("orderDetails.contactName.tip")}
                  />
                )}
                {email && (
                  <CardItem
                    label={translation("orderDetails.emailLabel")}
                    value={email}
                    tip={translation("orderDetails.email.tip")}
                  />
                )}
                {createdAt && (
                  <CardItem
                    label={translation("orderDetails.createdAtLabel")}
                    value={formatDateTime(createdAt)}
                    tip={translation("orderDetails.createdAt.tip")}
                  />
                )}
              </Col>
              <Col xs={24} lg={12}>
                {documentNumber && (
                  <CardItem
                    label={translation("orderDetails.documentNumberLabel")}
                    documentNumber={documentNumber}
                    tip={translation("orderDetails.documentNumber.tip")}
                  />
                )}
                {phoneNumber && (
                  <CardItem
                    label={translation("orderDetails.contactPhoneLabel")}
                    phoneNumber={phoneNumber}
                    tip={translation("orderDetails.contactPhone.tip")}
                  />
                )}
                {updatedAt && (
                  <CardItem
                    label={translation("orderDetails.updatedAtLabel")}
                    value={formatDateTime(updatedAt)}
                    tip={translation("orderDetails.updatedAt.tip")}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Redirect>
    </div>
  );
};

export default OrderItem;
