import React, { useEffect } from "react";
import { Layout } from "antd";
import { interceptor } from "helpers";
import { useSelector, useDispatch } from "react-redux";
import { Provider } from "react-redux";

import AppHeader from "../containers/Topbar/AppHeader";
import { CustomerSearchBar } from "../components/Customers/CustomerSearchBar";
import { OrdersSearchBar } from "../components/Order/OrdersSearchBar";

import store from "../store";

import { userSignOut } from "appRedux/actionCreators/Auth";

const { Content } = Layout;

const AppLayout = ({ match, authUser, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    interceptor();
  }, []);

  const getContainerSearchBar = (path) => {
    if (path.includes("/customers")) {
      return <CustomerSearchBar />;
    }
    if (path.includes("/orders")) {
      return <OrdersSearchBar />;
    }
    return null;
  };

  const showSearchBar = getContainerSearchBar(window.location.href);

  return (
    <Provider store={store}>
      <Layout className="gx-app-layout">
        <Layout>
          <AppHeader
            authUser={authUser}
            userSignOut={() => dispatch(userSignOut())}
            currentOrderData={{}}
          />
          {showSearchBar && <>{getContainerSearchBar(window.location.href)}</>}
          <Content
            className="gx-layout-content"
            style={{
              backgroundColor: "#fff",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Provider>
  );
};

export default AppLayout;
