import React from "react";
//PropTypes
import PropTypes from "prop-types";
//Custom Css
import "./Styles.less";
//Components
import { Pagination } from "antd";

const OrdersPagination = ({
  currentPage,
  pageSize,
  totalOrders,
  onPageChange,
}) => {
  return (
    <section className="ordersPagination">
      <Pagination
        current={currentPage + 1}
        onChange={onPageChange}
        pageSize={pageSize}
        total={totalOrders}
      />
    </section>
  );
};

export default OrdersPagination;

OrdersPagination.defaultProps = {
  pageSize: 30,
  totalOrders: 999999,
};
OrdersPagination.propTypes = {
  pageSize: PropTypes.number,
};
