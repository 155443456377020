import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.less";
import { Button, Checkbox, Popover, Row, Space, Tooltip } from "antd";
import { ChevronDown, X } from "react-feather";
import { stagesEnum } from "../../../constants";
import { CloseOutlined } from "@ant-design/icons";

const ProductTypeFilter = ({ types, onClean, onFilter, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t: translation } = useTranslation();

  const handleCleanFilter = () => onClean && onClean("types");
  const handleFilter = () => onFilter && onFilter();
  const handleChange = (filterType, event) =>
    onChange && onChange(filterType, event);
  const handleOpen = (newOpen) => setIsOpen(newOpen);

  const content = (
    <div style={{ padding: "6px 16px" }}>
      <Row className="gx-mb-3">
        <Checkbox
          name="CARGO_OPEN_POLICY"
          checked={types.CARGO_OPEN_POLICY}
          onChange={(e) => handleChange("types", e)}
        >
          {translation("ordersFilters.cargoOpenPolicy")}
        </Checkbox>
      </Row>
      <Row className="gx-mb-3">
        <Checkbox
          name="CARGO_SINGLE_BOARDING"
          checked={types.CARGO_SINGLE_BOARDING}
          onChange={(e) => handleChange("types", e)}
        >
          {translation("ordersFilters.cargoSingleBoarding")}
        </Checkbox>
      </Row>
      <Row className="gx-mb-3">
        <Checkbox
          name="LIFE"
          checked={types.LIFE}
          onChange={(e) => handleChange("types", e)}
        >
          {translation("ordersFilters.life")}
        </Checkbox>
      </Row>
      <Row className="gx-mb-3">
        <Checkbox
          name="SURETY"
          checked={types.SURETY}
          onChange={(e) => handleChange("types", e)}
        >
          {translation("ordersFilters.surety")}
        </Checkbox>
      </Row>
      <Row className="">
        <Button
          type="primary"
          ghost
          onClick={handleCleanFilter}
          className="gx-mb-0"
        >
          {translation("ordersFilters.cleanFilter.label")}
        </Button>
        <Button type="primary" onClick={handleFilter} className="gx-mb-0">
          {translation("ordersFilters.doFilter.label")}
        </Button>
      </Row>
    </div>
  );

  return (
    <Popover
      content={content}
      placement="bottomRight"
      trigger="click"
      className="gx-flex-row gx-align-items-center gx-cursor-pointer"
    >
      {translation("ordersFilters.productTypeFilter.label")}
      {!isOpen ? (
        <ChevronDown size={20} style={{ marginLeft: "4px" }} />
      ) : (
        <ChevronDown
          size={20}
          style={{ marginLeft: "4px", transform: "rotate(180deg)" }}
        />
      )}
    </Popover>
  );
};

const StagesFilter = ({ stages, onClean, onFilter, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t: translation } = useTranslation();

  const handleCleanFilter = () => onClean && onClean("stages");
  const handleFilter = () => onFilter && onFilter();
  const handleChange = (filterType, event) =>
    onChange && onChange(filterType, event);
  const handleOpen = (newOpen) => setIsOpen(newOpen);

  const content = (
    <div style={{ padding: "6px 16px" }}>
      <Row className="gx-mb-3">
        <Checkbox
          name="CREATED"
          checked={stages[stagesEnum.CREATED]}
          onChange={(e) => handleChange("stages", e)}
        >
          {translation("ordersFilters.stageFilter.created")}
        </Checkbox>
      </Row>
      <Row className="gx-mb-3">
        <Checkbox
          name="PENDING"
          checked={stages[stagesEnum.PENDING]}
          onChange={(e) => handleChange("stages", e)}
        >
          {translation("ordersFilters.stageFilter.pending")}
        </Checkbox>
      </Row>
      <Row className="gx-mb-3">
        <Checkbox
          name="APPROVED"
          checked={stages[stagesEnum.APPROVED]}
          onChange={(e) => handleChange("stages", e)}
        >
          {translation("ordersFilters.stageFilter.approved")}
        </Checkbox>
      </Row>
      <Row className="gx-mb-3">
        <Checkbox
          name="ARCHIVED"
          checked={stages[stagesEnum.ARCHIVED]}
          onChange={(e) => handleChange("stages", e)}
        >
          {translation("ordersFilters.stageFilter.archived")}
        </Checkbox>
      </Row>

      <Row className="">
        <Button
          type="primary"
          ghost
          onClick={handleCleanFilter}
          className="gx-mb-0"
        >
          {translation("ordersFilters.cleanFilter.label")}
        </Button>
        <Button type="primary" onClick={handleFilter} className="gx-mb-0">
          {translation("ordersFilters.doFilter.label")}
        </Button>
      </Row>
    </div>
  );

  return (
    <Popover
      content={content}
      placement="bottomRight"
      trigger="click"
      className="gx-flex-row gx-align-items-center gx-cursor-pointer"
    >
      {translation("ordersFilters.stageFilter.label")}
      {!isOpen ? (
        <ChevronDown size={20} style={{ marginLeft: "4px" }} />
      ) : (
        <ChevronDown
          size={20}
          style={{ marginLeft: "4px", transform: "rotate(180deg)" }}
        />
      )}
    </Popover>
  );
};

export const OrdersFilters = ({
  types,
  stages,
  onChange,
  onClean,
  onFilter,
  onReset,
}) => {
  const { t: translation } = useTranslation();

  const handleCleanFilter = (filterType) => onClean && onClean(filterType);
  const handleFilter = () => onFilter && onFilter();
  const handleChange = (filterType, event) =>
    onChange && onChange(filterType, event);

  const handleResetFilters = () => onReset && onReset();

  return (
    <div className="orders-filters-container">
      <div className="gx-container orders-filters-content">
        <span className="orders-filters-title">
          {translation("ordersFilters.title")}
        </span>
        <div className="orders-filters">
          <ProductTypeFilter
            types={types}
            onChange={handleChange}
            onClean={handleCleanFilter}
            onFilter={handleFilter}
          />
          <StagesFilter
            stages={stages}
            onChange={handleChange}
            onClean={handleCleanFilter}
            onFilter={handleFilter}
          />
          <Tooltip
            placement="bottomRight"
            title={translation("ordersFilters.resetFilters.tip")}
          >
            <Button
              onClick={handleResetFilters}
              style={{
                margin: 0,
                padding: 0,
                border: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              <X />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
