// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../util/axios";
// translation
import i18n from "../../../translation/i18n";
// antd
import { message } from "antd";

export const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    errors: [],
    isLoading: false,
    ordersList: [],
    createOrderData: {},
    pagination: {
      page: 0,
      quantity: 30,
    },
    filters: {
      types: {
        CARGO_OPEN_POLICY: true,
        CARGO_SINGLE_BOARDING: true,
        LIFE: true,
        SURETY: true,
      },
      stages: {
        CREATED: true,
        PENDING: false,
        APPROVED: false,
        ARCHIVED: false,
      },
    },
    searchData: {
      selectedFilter: "DEFAULT_LABEL",
      searchValue: "",
      requestUrl: "",
      results: [],
      errors: [],
      isSearching: false,
    },
  },
  reducers: {
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCreateOrderData: (state, action) => {
      state.createOrderData = action.payload;
    },
    setSelectedFilter: (state, action) => {
      state.searchData.selectedFilter = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchData.searchValue = action.payload;
    },
    setRequestUrl: (state, action) => {
      state.searchData.requestUrl = action.payload;
    },
    setResults: (state, action) => {
      state.searchData.results = action.payload;
    },
    setSearching: (state, action) => {
      state.searchData.isSearching = action.payload;
    },
    setSearchErrors: (state, action) => {
      state.searchData.errors = action.payload;
    },
    setOrdersList: (state, action) => {
      state.ordersList = action.payload;
    },
    setPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setResetTypes: (state) => {
      state.filters.types = {
        CARGO_OPEN_POLICY: true,
        CARGO_SINGLE_BOARDING: true,
        LIFE: true,
        SURETY: true,
      };
    },
    setResetStages: (state) => {
      state.filters.stages = {
        CREATED: true,
        PENDING: false,
        APPROVED: false,
        ARCHIVED: false,
      };
    },
  },
});

// ------------------------------------------------------------------

export const {
  setErrors,
  setIsLoading,
  setCreateOrderData,
  setSelectedFilter,
  setSearchValue,
  setRequestUrl,
  setResults,
  setSearching,
  setSearchErrors,
  setOrdersList,
  setPage,
  setFilters,
  setResetTypes,
  setResetStages,
} = ordersSlice.actions;

export default ordersSlice.reducer;

// -----------------------------------------------------------------

const configCreateOrderEndpoint = {
  surety: "/surety/v2/backoffice",
  cargo: "/cargo/v1/backoffice",
  life: "/life/v1/backoffice",
};

export const createOrder = (data, productType) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .post(`${configCreateOrderEndpoint[productType]}`, {
        type: data?.type,
        origin: data?.origin,
        salesRepresentativeId: data?.salesRepresentativeId,
        dealType: data?.dealType,
        initialData: {
          name: data?.name,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
          phoneType: "WHATSAPP_PHONE_NUMBER",
          receiveWhatsAppMessage: true,
          contactType: "COMPANY",
          hasDocumentNumber: true,
          documentNumber: data?.documentNumber,
        },
      })
      .then((response) => {
        message.success(i18n.t("order.createOrder.successMessage"));
        return resolve(response);
      })
      .catch((error) => {
        if (
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          dispatch(setErrors(error.response.data.errors));
        }
        return reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

export const sendSingleBoardingDealToHubspot = (code, email) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`/cargo/v1/single-boarding/${code}/email`, { email })
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        return reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

// Order Search Requests ------------------------------------------------------------------

export const getOrdersSearchData = () => (dispatch, getState) => {
  const {
    orders: {
      searchData: { requestUrl },
    },
  } = getState();
  dispatch(setSearching(true));
  return new Promise((resolve, reject) => {
    axios
      .get(`${requestUrl}`)
      .then((response) => {
        dispatch(setSearching(false));

        if (response?.data?.length === 0) {
          dispatch(
            setResults([
              {
                description: "NO_RESULTS_FOUND",
              },
            ])
          );
        } else {
          dispatch(setResults(response?.data));
        }
      })
      .catch((error) => {
        dispatch(setSearching(false));
        message.error(error.response?.data?.message);
        reject(error);
      })
      .finally((response) => {
        resolve(response);
      });
  });
};

// Fetch Orders ------------------------------------------------------------------

export const fetchOrders = () => (dispatch, getState) => {
  const {
    orders: { filters, pagination },
  } = getState();

  const searchParamsConfig = {
    page: pagination?.page,
    quantity: pagination?.quantity,
    status: Object.keys(filters.stages).filter((key) => filters.stages[key]),
    types: Object.keys(filters.types).filter((key) => filters.types[key]),
  };

  const searchParams = new URLSearchParams(searchParamsConfig);
  dispatch(setIsLoading(true));
  return new Promise((resolve, reject) => {
    axios
      .get(`/insurance/v1/?${searchParams}`)
      .then((response) => {
        dispatch(setOrdersList(response.data));
        dispatch(setIsLoading(false));
      })
      .then((response) => resolve(response))
      .catch((error) => {
        dispatch(setIsLoading(false));
        reject(error);
      });
  });
};
