import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const useOrdersSearch = () => {
  const { t: translation } = useTranslation();
  const DEFAULT_VALUE = "DEFAULT_LABEL";

  const OrdersSearchFieldsEnum = {
    documentNumber: "documentNumber",
    companyName: "companyName",
    contactName: "contactName",
    email: "email",
    phoneNumber: "phoneNumber",
    DEFAULT_LABEL: "DEFAULT_LABEL",
  };
  const searchFilterOptions = [
    {
      label: translation("orders.searchBar.selectFilter.label"),
      value: DEFAULT_VALUE,
    },
    {
      label: translation("orders.searchBar.selectFilter.documentNumber"),
      value: OrdersSearchFieldsEnum.documentNumber,
    },
    {
      label: translation("orders.searchBar.selectFilter.companyName"),
      value: OrdersSearchFieldsEnum.companyName,
    },
    {
      label: translation("orders.searchBar.selectFilter.contactName"),
      value: OrdersSearchFieldsEnum.contactName,
    },
    {
      label: translation("orders.searchBar.selectFilter.email"),
      value: OrdersSearchFieldsEnum.email,
    },
    {
      label: translation("orders.searchBar.selectFilter.phoneNumber"),
      value: OrdersSearchFieldsEnum.phoneNumber,
    },
  ];

  const resolvePolicyPath = (data) => {
    return `/crm/orders/${data?.optionValue}`;
  };

  function searchRequestsFactory(searchFilter, value) {
    const searchParamsConfig = {
      page: 0,
      quantity: 5,
      status: "CREATED",
      types: "CARGO_OPEN_POLICY,CARGO_SINGLE_BOARDING,LIFE,SURETY",
    };

    const searchParams = new URLSearchParams({
      ...searchParamsConfig,
      [OrdersSearchFieldsEnum[searchFilter]]: value,
    });

    return {
      getEndpoint: () => `/insurance/v1/?${searchParams}`,
      getRedirectPath: (data) => resolvePolicyPath(data),
    };
  }

  function getSearchRequest(searchFilter, value) {
    const searchRequests = searchRequestsFactory(searchFilter, value);
    return searchRequests.getEndpoint();
  }

  function getRedirectPath(searchFilter, value) {
    const searchRequests = searchRequestsFactory();
    return searchRequests.getRedirectPath(value);
  }

  const ordersSearchSchema = Yup.object({
    selectedFilter: Yup.string(),
    searchValue: Yup.string().nullable(),
  });

  return {
    DEFAULT_VALUE,
    searchFilterOptions,
    ordersSearchSchema,
    getSearchRequest,
    getRedirectPath,
  };
};
