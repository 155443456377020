import React, { Fragment, useEffect, useRef } from "react";
import AppLayout from "../../../layouts/AppLayout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrders,
  setFilters,
  setPage,
  setResetStages,
  setResetTypes,
} from "../../../store/slices/orders";
import OrderItem from "../../../components/Order/OrderList/OrderItem";
import { Empty, Skeleton, Spin } from "antd";
import "./styles.less";
import OrdersPagination from "../../../components/Order/OrderList/OrdersPagination";
import { OrdersFilters } from "../../../components/Order/OrdersFilters";

const OrdersList = ({}) => {
  const { t: translation } = useTranslation();
  const ordersRef = useRef(null);
  const dispatch = useDispatch();
  const { ordersList, isLoading, pagination, filters } = useSelector(
    (state) => state.orders
  );
  const ordersCheck = ordersList.length === 0 && !isLoading;

  const scrollView = () => {
    if (ordersRef)
      ordersRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
  };
  // Pagination
  function handlePageChange(page) {
    dispatch(setPage(page - 1));
    dispatch(fetchOrders(page)).then(() => scrollView());
  }

  // Filters
  const handleFilterChange = (filterType, event) => {
    if (filterType === "stages") {
      const newStages = Object.keys(filters.stages).reduce((acc, key) => {
        acc[key] = key === event.target.name;
        return acc;
      }, {});
      dispatch(
        setFilters({
          ...filters,
          [filterType]: newStages,
        })
      );
      return;
    }

    dispatch(
      setFilters({
        ...filters,
        [filterType]: {
          ...filters[filterType],
          [event.target.name]: event.target.checked,
        },
      })
    );
  };

  const handleCleanFilter = (filterType) => {
    dispatch(setPage(0));
    if (filterType === "types") {
      dispatch(setResetTypes());
    }
    if (filterType === "stages") {
      dispatch(setResetStages());
    }
    dispatch(fetchOrders());
  };

  const handleFilter = () => {
    dispatch(setPage(0));
    dispatch(fetchOrders());
  };

  const handleResetFilters = () => {
    dispatch(setPage(0));
    dispatch(setResetTypes());
    dispatch(setResetStages());
    dispatch(fetchOrders());
  };

  useEffect(() => {
    dispatch(fetchOrders());
  }, []);

  return (
    <div className="gx-main-content orders-container" ref={ordersRef}>
      <div className="orders-filters">
        <OrdersFilters
          types={filters?.types}
          stages={filters?.stages}
          onChange={handleFilterChange}
          onClean={handleCleanFilter}
          onFilter={handleFilter}
          onReset={handleResetFilters}
        />
      </div>

      <div className="spin-wrapper">
        <Spin
          spinning={isLoading}
          tip={translation("orders.spin.label")}
          style={{ marginTop: 200 }}
        >
          {ordersCheck ? (
            <Empty
              imageStyle={{ height: 120 }}
              description={<span>{translation("orders.emptyList")}</span>}
            />
          ) : (
            ordersList.map((order) => (
              <div key={order.id} style={{ borderBottom: "1px solid #D9D9D9" }}>
                <div className="gx-container ">
                  <OrderItem order={order} />
                </div>
              </div>
            ))
          )}
          <div className="gx-container gx-pb-4">
            {ordersList.length > 0 && (
              <OrdersPagination
                currentPage={pagination?.page}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
};

const OrdersContainer = (props) => (
  <AppLayout>
    <OrdersList {...props} />
  </AppLayout>
);

OrdersContainer.defaultProps = {};

export default OrdersContainer;
