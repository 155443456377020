import React, { Fragment } from "react";
//Translation
import { useTranslation } from "react-i18next";
// Components
import { Drawer } from "antd";
//Data Configs
import Config from "../Config";
//Constants
const { stagesEntries, typeEntries } = Config;

const Stages = (props) => {
  const { disableView, activeStage, updateData } = props;

  const onStageClick = (stage) => () => {
    if (!disableView && stage !== activeStage) updateData("stage", stage);
  };
  const checkBoxStyle = { userSelect: "none" };
  if (disableView) checkBoxStyle.cursor = `not-allowed`;

  return stagesEntries.map((stage) => {
    const active = stage.type === activeStage;
    return (
      <li key={stage.type} onClick={onStageClick(stage.type)}>
        <span
          className={active ? "gx-link active" : "gx-link"}
          style={checkBoxStyle}
        >
          <i className={`icon icon-${stage.icon}`} style={checkBoxStyle} />
          <span style={checkBoxStyle}>{stage.title}</span>
        </span>
      </li>
    );
  });
};

const Types = (props) => {
  const { disableView, types, updateData } = props;

  const onTypeClick = (type) => () => {
    if (!disableView) {
      const updatedTypes = { ...types, [type]: !types[type] };
      updateData("types", updatedTypes);
    }
  };
  const checkBoxStyle = { userSelect: "none" };
  if (disableView) checkBoxStyle.cursor = `not-allowed`;

  return typeEntries.map((entry) => {
    const active = types[entry.type] ? "circle" : "circle-o";
    return (
      <li key={entry.type}>
        <span
          className="gx-link"
          onClick={onTypeClick(entry.type)}
          style={checkBoxStyle}
        >
          <i
            className={`icon icon-${active} gx-text-${entry.color}`}
            style={checkBoxStyle}
          />
          <span style={checkBoxStyle}>{entry.title}</span>
        </span>
      </li>
    );
  });
};

const OrdersDrawer = (props) => {
  const {
    visible,
    disableView,
    closeSidebar,
    stage,
    types,
    updateData,
  } = props;

  const { t: translation } = useTranslation();

  const children = (
    <div className="gx-module-side">
      <div className="gx-module-side-content">
        <ul className="gx-module-nav">
          <li className="gx-module-nav-label">
            {translation("order.orderDrawer.stagesTitleLabel")}
          </li>
          <Stages
            activeStage={stage}
            updateData={updateData}
            disableView={disableView}
          />
          <li className="gx-module-nav-label">
            {translation("order.orderDrawer.typesTitleLabel")}
          </li>
          <Types
            types={types}
            updateData={updateData}
            disableView={disableView}
          />
        </ul>
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className="gx-d-block gx-d-lg-none">
        <Drawer
          placement="left"
          closable={false}
          visible={visible}
          onClose={closeSidebar}
        >
          {children}
        </Drawer>
      </div>
      <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">{children}</div>
    </Fragment>
  );
};

export default OrdersDrawer;
