import React, { Component } from "react";
//Other Libs
import URLSearchParams from "url-search-params";
import { Redirect, Route, Switch } from "react-router-dom";
import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";
import AppLocale from "lngProvider";
//Custom Components
import MainApp from "./MainApp";
import OrdersContainer from "../Orders/Orders";

import SignIn from "../SignIn";
import RestrictedRoute from "./RestrictedRoute";
import ForgotPassword from "../Authorization/ForgotPassword";
import ChangePassword from "../Authorization/ChangePassword";
//Redux Bindings
import { connect } from "react-redux";
//Actions Creators
import {
  setInitUrl,
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from "appRedux/actionCreators";
//Action Types
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../appRedux/actionTypes";
import { userSignOut } from "appRedux/actionCreators/Auth";

// new redux
import { Provider } from "react-redux";
import reduxStore from "../../store";

import "moment/locale/pt-br";
import { getSessionStart } from "../../helpers/localStorage";

class App extends Component {
  constructor(props) {
    super(props);
    this.interval = null; // Para armazenar o intervalo de verificação
    this.checkSessionExpiration = this.checkSessionExpiration.bind(this); // Bind necessário para acessar o 'this'
  }
  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  componentDidMount() {
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get("theme"));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get("nav-style"));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get("layout-type"));
    }

    this.checkSessionExpiration();
    this.interval = setInterval(this.checkSessionExpiration, 60 * 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  checkSessionExpiration() {
    const loginTime = getSessionStart();
    const currentTime = new Date().getTime();
    const nineHours = 9 * 60 * 60 * 1000; // 9 horas em milissegundos

    if (loginTime && currentTime - loginTime > nineHours) {
      this.props.userSignOut();
    }
  }

  render() {
    const {
      match,
      location,
      layoutType,
      navStyle,
      locale,
      authUser,
      currentOrderData,
      initURL,
    } = this.props;

    if (location.pathname === "/") {
      if (authUser === null) {
        return <Redirect to={"/signin"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        return <Redirect to={"/main/dashboard/crm"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }
    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Switch>
            <Route exact path="/signin" component={SignIn} />
            <Route
              exact
              path="/forgot-password"
              render={() => (
                <Provider store={reduxStore}>
                  <ForgotPassword />
                </Provider>
              )}
            />
            <Route
              exact
              path="/change-password"
              render={() => (
                <Provider store={reduxStore}>
                  <ChangePassword userId={authUser.id} />
                </Provider>
              )}
            />
            <RestrictedRoute
              path="/crm/orders"
              exact
              authUser={authUser}
              currentOrderData={currentOrderData}
              component={OrdersContainer}
            />
            <RestrictedRoute
              path={`${match.url}`}
              authUser={authUser}
              currentOrderData={currentOrderData}
              component={MainApp}
            />
          </Switch>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth, crm }) => {
  const { locale, navStyle, layoutType } = settings;
  const { authUser, initURL } = auth;
  const { configurations: currentOrderData } = crm?.order;
  return { locale, navStyle, layoutType, authUser, initURL, currentOrderData };
};
export default connect(mapStateToProps, {
  setInitUrl,
  setThemeType,
  onNavStyleChange,
  onLayoutTypeChange,
  userSignOut,
})(App);
