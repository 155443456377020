// redux-toolkit
import { configureStore } from "@reduxjs/toolkit";
// reducers
import policy from "./slices/policy";
import customer from "./slices/customer";
import limits from "./slices/limits";
import proposal from "./slices/proposal";
import invoice from "./slices/invoice";
import common from "./slices/common";
import order from "./slices/order";
import orders from "./slices/orders";
import auth from "./slices/auth";
import user from "./slices/user";
import endorsement from "./slices/endorsement";
import cargoOpenPolicy from "./slices/cargoOpenPolicy";
import clientApp from "./slices/clientApp";
import emission from "./slices/emission";

// ---------------------------------------------------------------------

const store = configureStore({
  reducer: {
    customer,
    orders,
    order,
    proposal,
    limits,
    policy,
    invoice,
    common,
    auth,
    user,
    endorsement,
    cargoOpenPolicy,
    clientApp,
    emission,
  },
});

export default store;
